<template>
  <div class="setting-page">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in ['后台角色','后台用户','应用用户组']"
        :key="index"
        :label="item"
        :name="index | changeNum"
      />
      <role-setting v-if="activeName == 0" />
      <user-setting v-if="activeName == 1" />
      <user-group v-if="activeName == 2" />
    </el-tabs>
  </div>
</template>

<script>
export default {
  filters: {
    changeNum(val) {
      return String(val);
    },
  },
  components: {
    "role-setting": () => import("./components/role-setting"),
    "user-setting": () => import("./components/user-setting"),
    "user-group": () => import('./components/user-group')
  },
  data() {
    return {
      activeName: "0",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(tab) {
      this.activeName = tab.index
    },
  },
};
</script>

<style lang="less" scoped>
.setting-page {
  margin-top: 20px;
  /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}
</style>
